import { RouteComponentProps } from "@reach/router";
import React from "react";
import Container from "react-bootstrap/Container";
import logo from "../assets/images/icon_large.jpg";
import SizedBox from "../components/SizedBox";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { useFormik } from "formik";
import * as yup from "yup";
import "../styles/reset_password.css";
import { useMutation } from "@apollo/client";
import { ResetPasswordMutation } from "../graphql/mutations";
import { Spinner } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

interface ResetPasswordComponentProps extends RouteComponentProps {
  id?: any;
}

const ResetPassword = (props: ResetPasswordComponentProps) => {
  const [resetPassword, { loading, error, called, data }] = useMutation(
    ResetPasswordMutation,
    {
      onError: (error: any) => console.log(error),
    }
  );

  const formValidator = yup.object().shape({
    password: yup
      .string()
      .required("Enter a password")
      .min(6, "Password must have a minimum of 6 characters"),
    confPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      confPassword: "",
    },
    validationSchema: formValidator,
    onSubmit: (values: any) => {
      resetPassword({
        variables: {
          where: {
            password: values.password,
            ticket: `${props.id}`,
          },
        },
      });
    },
  });
  return (
    <Container>
      <SizedBox height={40} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ borderRadius: "50%", width: 130, height: 130 }}
          src={logo}
          alt="Smart Saver Logo"
        />
      </div>
      <SizedBox height={20} />
      <h2 style={{ textAlign: "center" }}>Reset your password.</h2>
      <p style={{ textAlign: "center" }}>
        Enter your details below to begin the reset process.
      </p>
      <SizedBox height={20} />
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                onChange={handleChange}
                value={values.password}
              />
              <span className="help-text">
                {errors.password && touched.password ? errors.password : null}
              </span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                name="confPassword"
                onChange={handleChange}
                value={values.confPassword}
              />
              <span className="help-text">
                {errors.confPassword && touched.confPassword
                  ? errors.confPassword
                  : null}
              </span>
            </Form.Group>
            <ButtonToolbar>
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Button type="submit" variant="success">
                  Save
                </Button>
              )}
            </ButtonToolbar>
            <hr />
            {called && !loading ? (
              <Alert variant={called && error ? "danger" : "success"}>
                {called && error
                  ? "Unable to reset your password"
                  : "Successfully reset your password. Please login to your app"}
              </Alert>
            ) : null}
          </Form>
        </Card.Body>
      </Card>
      <footer
        style={{
          backgroundColor: "#fafafa",
          textAlign: "center",
          marginTop: 50,
          color: "#ccc",
          fontSize: ".8em",
        }}
      >
        <p>
          Copyright &copy;{" "}
          <a
            target="blank"
            style={{ color: "#555" }}
            href="https://www.smartsaverzambia.com"
          >
            Smart Saver Zambia LLC
          </a>
          . All rights reserved.
        </p>
      </footer>
    </Container>
  );
};

export default ResetPassword;
