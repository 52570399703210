import { Router } from "@reach/router";
import ResetPassword from "./routes/ResetPassword";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: process.env.REACT_APP_SERVER_URL,
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <ResetPassword path="/reset_password/:id" />
      </Router>
    </ApolloProvider>
  );
};

export default App;
